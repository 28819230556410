
import { v4 as uuidv4 } from 'uuid';

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Generate or retrieve a session ID
  let sessionId = sessionStorage.getItem('session_id');
  if (!sessionId) {
    sessionId = uuidv4();
    sessionStorage.setItem('session_id', sessionId);
  }

  // Collect tracking data
  const trackingData = {
    user_id: 1, // Replace with actual user ID if available
    session_id: sessionId,
    page_url: window.location.href,
    referrer_url: prevLocation ? prevLocation.href : document.referrer || null,
    user_agent: navigator.userAgent,
    // IP address will be added server-side
  };

  // Send data to the server
  fetch('https://verum.com/api/track', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(trackingData),
  }).catch((error) => {
    console.error('Error sending tracking data:', error);
  });
};